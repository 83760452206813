<script setup>
import { ref } from "vue";
import BaseCard from '@/components/BaseCard.vue';

const cardCount = ref(8)
</script>

<template>
  <section class="home-page bg-color-secondary">
    <div class="container">
      <div class="home-page__texts m-auto">
        <h2 class="text-align-center font-gotham text-capitalize text-size-2xl-desktop text-size-xl text-color-white">
          Experienced & Multi Talented
        </h2>

        <hr class="my-s">

        <p class="text-size-md font-proxima text-color-tertiary text-align-center mb-xl">
          Lorem ipsum dolor sit amet,
          consectetur adipiscing elit. Aliquam tortor leo, porttitor vel dictum at,
          aliquam et augue. Duis vel molestie risus, ut lobortis felis.
        </p>
      </div>

      <div class="home-page__cards">
        <base-card
          v-for="(index) in cardCount"
          :key="index"
          show-header
          content-header="Block"
          content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tortor leo, porttitor vel dictum at, aliquam et augue. Duis vel molestie risus, ut lobortis felis."
        />
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/home-page";
</style>
