<script setup>
import { defineProps, ref, onMounted } from 'vue'

defineProps({
  showHeader: Boolean,
  content: String,
  contentHeader: String
})

const contentBlock = ref(null)
const isOverflowing = ref(false)
const isExpanded = ref(false)

onMounted(() => {
  isOverflowing.value = checkOverflow(contentBlock.value)
})

function checkOverflow(el) {
  const curOverflow = el.style.overflow;

  if ( !curOverflow || curOverflow === 'visible' )
    el.style.overflow = 'hidden';

  const isOverflowing = el.clientWidth < el.scrollWidth
    || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;

  return isOverflowing;
}

function expandContent() {
  if (isExpanded.value) {
    contentBlock.value.style.maxHeight = '38px'
    isExpanded.value = !isExpanded.value

    return
  }

  contentBlock.value.style.maxHeight = `${contentBlock.value.scrollHeight}px`
  isExpanded.value = !isExpanded.value
}

</script>

<template>
  <div class="base-card">
    <div
      v-if="showHeader"
      class="base-card__header text-color-white bg-color-primary py-xxxl"
    >
      <img src="../assets/images/card-information.png" alt="Card logo">
    </div>
    <div class="base-card__content-wrapper bg-color-black py-xxxl px-xxl">
      <h3
        v-if="contentHeader"
        class="text-size-sm text-uppercase text-color-white font-gotham mb-l"
      >
        {{ contentHeader }}
      </h3>

      <div class="base-card__content">
        <p
          v-if="content"
          ref="contentBlock"
          class="base-card__content-text font-proxima text-size-md text-color-tertiary text-align-center"
        >
          {{ content }}
        </p>

        <div
          class="base-card__button bg-color-button m-auto mt-xl"
          @click="expandContent"
        >
          <img
            v-if="isOverflowing && !isExpanded"
            src="../assets/images/plus-icon.png"
            alt="Read more"
          >

          <img
            v-if="isOverflowing && isExpanded"
            src="../assets/images/minus-icon.png"
            alt="Read less"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/base-card";
</style>
